import React from 'react'
import { themeGet } from '@primer/components'
import styled from 'styled-components'

import PageWrapper from './PageWrapper'
import SEO from '../reusable/Seo'
import PageTitle from '../reusable/PageTitle'

const PageWrapperBorder = styled(PageWrapper)`
  margin-top: calc((${themeGet('space.4')} * 2) + ${themeGet('space.6')});
  border-top: ${themeGet('borders.1')} ${themeGet('colors.border.gray')};
`

const PageSetup = ({
  pageTitle,
  siteTitle,
  darkerBackground,
  children,
  ...pageWrapperProps
}) => (
  <>
    <SEO title={siteTitle || pageTitle} />

    {darkerBackground ? (
      <PageWrapperBorder bg="accent.0" {...pageWrapperProps}>
        {pageTitle && <PageTitle title={pageTitle} />}

        {children}
      </PageWrapperBorder>
    ) : (
      <PageWrapper {...pageWrapperProps}>
        {pageTitle && <PageTitle title={pageTitle} darkerBackground />}

        {children}
      </PageWrapper>
    )}
  </>
)

export default PageSetup
