import React from 'react'
import { Flex, Box } from '@primer/components'

const PageWrapper = ({ title, children, ...rest }) => {
  return (
    <Flex
      as={Box}
      px={4}
      alignItems="center"
      flexDirection="column"
      width={1}
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default PageWrapper
