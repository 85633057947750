import React from 'react'
import { Heading, Box } from '@primer/components'

const PageTitle = ({ title, darkerBackground }) => {
  return (
    <Box as="header" mt={darkerBackground ? 8 : 5}>
      <Heading as="h1" fontSize={[5, 6]}>
        {title}
      </Heading>
    </Box>
  )
}

export default PageTitle
